<template>
  <VDialog :icon="props.icon" :title="props.title">
    <VParagraph v-a11y:dialog:description color="neutral/500" class="text-center" v-html="props.message" />
    <template #buttons>
      <VButton v-for="(button, buttonIndex) of buttons" :key="buttonIndex" size="sm" :variant="button.variant" :color="button.color" @click="emit('close', button.value)">
        {{ button.label }}
      </VButton>
    </template>
  </VDialog>
</template>
<script lang="ts" setup>
import VDialog from './VDialog.vue'
import VButton from '@component-library/buttons/VButton.vue';
import type { Icon } from '@icons/index';
import { computed } from 'vue';
import type { V } from '@component-utils/types';
import VParagraph from '@component-library/labels/VParagraph.vue';
import { useLocalize } from '@component-utils/localization';
import { vA11y } from '@component-library/directives';

const localize = useLocalize('component-library.dialogs')

defineOptions({
  name: 'VSimpleDialog'
})

const props = withDefaults(
  defineProps<{
    message: string
    icon?: Icon
    title?: string
    buttons?: (keyof typeof PREDEFINED_BUTTONS | V.SimpleDialog.Button)[]
  }>(),
  {
    icon: undefined,
    title: undefined,
    buttons: () => ['close']
  }
)

const emit = defineEmits<{
  close: [V.SimpleDialog.Button['value']]
}>()

const buttons = computed(() => props.buttons.map((button) => typeof button === 'string' ? PREDEFINED_BUTTONS[button] : button))

const PREDEFINED_BUTTONS: Record<string, V.SimpleDialog.Button> = {
  'close': {
    value: false,
    label: localize('default_close_label'),
    variant: 'outlined',
    color: 'secondary'
  },
  'continue': {
    value: true,
    label: localize('default_continue_label'),
    variant: 'contained',
    color: 'primary'
  },
  'no': {
    value: false,
    label: localize('default_no_label'),
    variant: 'outlined',
    color: 'secondary'
  },
  'ok': {
    value: true,
    label: localize('default_ok_label'),
    variant: 'contained',
    color: 'primary'
  },
  'confirm': {
    value: true,
    label: localize('default_confirm_label'),
    variant: 'contained',
    color: 'primary'
  },
  'cancel': {
    value: false,
    label: localize('default_cancel_label'),
    variant: 'outlined',
    color: 'secondary'
  }
}
</script>